import api from "../api";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        delivery_timings: []
    },
    getters: {
        delivery_timings: state => state.delivery_timings
    },
    mutations: {
        setDeliveryTimings(state, data) {
            state.delivery_timings = data;
        }
    },
    actions: {
        async getDeliveryTimings({commit}, filters) {
            try {
                const {data} = await api.getDeliveryTimings(filters);
                commit('setDeliveryTimings', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }
}

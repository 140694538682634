// import Swiper JS
import Swiper, {Navigation, Lazy} from "swiper"
import 'swiper/swiper-bundle.min.css'
// import 'swiper/css'

class Sliders {
    constructor() {
        this.initCenteredSlider()
        this.initCardSlider()
        this.initReviewSlider()
    }

    initCenteredSlider() {
        new Swiper(".centered-slider", {
            modules: [Lazy],
            speed: 400,
            spaceBetween: 30,
            slidesPerView: 2,
            slidesPerGroup: 1,
            centeredSlides: true,
            breakpoints: {
                560: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                768: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 4
                },
                1280: {
                    slidesPerView: 6
                }
            },
            loop: true,
            preloadImages: false,
            lazy: true
        })
    }

    initReviewSlider() {
         new Swiper(".review-slider", {
            modules: [Navigation, Lazy],
            speed: 400,
            spaceBetween: 30,
            slidesPerView: 1,
            breakpoints: {
                560: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                768: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                1024: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                1200: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                }
            },
            preloadImages: false,
            lazy: true,
            navigation: {
                nextEl: ".swiper-button-next-rev",
                prevEl: ".swiper-button-prev-rev"
            }
        })
    }

    initCardSlider() {
        new Swiper(".card-slider-4-columns", {
            modules: [Navigation, Lazy],
            speed: 400,
            spaceBetween: 30,
            slidesPerView: 1,
            breakpoints: {
                560: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1024: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1200: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            },
            preloadImages: false,
            lazy: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        })
    }
}

export default Sliders

import client from "./client";

export default {
    async getFaculties(filters) {
        return client.parseResponse(await client.get('/faculties', filters))
    },
    async createFaculty(payload) {
        return client.parseResponse(await client.post('/faculties', payload))
    },
    async deleteFaculty(id){
        return client.parseResponse(await client.delete('/faculties/'+id))
    },
    async getFaculty(id){
        return client.parseResponse(await client.get('/faculties/'+id))
    },
    async updateFaculty(payload){
        return client.parseResponse(await client.put('/faculties/'+payload.id,payload))
    }
}

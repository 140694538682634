import api from "../api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        notification_settings: [],
        faqs: []
    },
    getters: {
        notification_settings: state => state.notification_settings,
        faqs: state => state.faqs
    },
    mutations: {
        setNotificationSettings(state, settings) {
            state.notification_settings = settings;
        },
        setFAQs(state,data){
            state.faqs = data;
        }
    },
    actions: {
        async getNotificationSettings({commit}, filters) {
            try {
                const response = await api.getNotificationSettings(filters);
                commit('setNotificationSettings', response);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getFAQs({commit}, filters) {
            try {
                const response = await api.getFAQs(filters);
                commit('setFAQs', response);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }
}

import client from "./client";
import store from "../store";

export default {
    async getInstitutionLocations(filters) {
        return client.parseResponse(await client.get('/institution-locations', filters));
    },
    async getIndustries(filters) {
        return client.parseResponse(await client.get('/industries', filters));
    },
    async getInstitutionTypes(filters){
        return client.parseResponse(await client.get('/institution-type', filters));
    },
    async getInstitutionsIFollow(filters){
        return client.parseResponse(await client.get('/follow', filters));
    },
    async checkInstitutionExists(filters) {
        return client.parseResponse(await client.get('/institution-exists', filters));
    },
    async getCountries(filters) {
        return client.parseResponse(await client.get('/countries', filters));
    },
    async getContinents(filters) {
        return client.parseResponse(await client.get('/continents', filters));
    },
    async getFinancialAids(filters){
        const loggedIn = store.getters['profile/loggedIn'];
        return client.parseResponse(await client.get(loggedIn ? '/programme-financial-aids' : '/programme-financial-aid', filters));
    },
    async deleteFinancialAid(id){
      return client.parseResponse(await client.delete('/programme-financial-aid/'+id))
    },
    async updateFinancialAid(payload){
        return client.parseResponse(await client.put('/programme-financial-aid/'+payload.id,payload))
    },
    async createInstitution(payload) {
        return client.parseResponse(await client.upload('/institutions', payload, payload.logo));
    },
    async createAdmissionEmails(payload){
      return client.parseResponse(await  client.post('/admission-emails',payload))
    },
    async updateInstitution(payload){
        return client.parseResponse(await client.upload('/institution/'+payload.id, payload, payload.logo));
    },
    async deleteInstitution(payload) {
        return client.parseResponse(await client.delete('/institution/' + payload.id, payload))
    },
    async acceptInvitation(payload) {
        return client.parseResponse(await client.get('/accept/invitation', payload))
    },
    async removeInstitutionPageAdmin(payload) {
        return client.parseResponse(await client.delete('/invitation/' + payload.id, payload))
    },
    async createInstitutionClaim(payload) {
        return client.parseResponse(await client.post('/institution-claim', payload))
    },
    async getInstitution(payload) {
        const loggedIn = store.getters['profile/loggedIn'];
        return client.parseResponse(await client.get((loggedIn ? 'institution/' + payload.id : 'institutions/' + payload.id),payload));
    },
    async followInstitution(institution) {
        return client.parseResponse(await client.post('/follow/' + institution.id));
    },
    async getInstitutions(filters) {
        return client.parseResponse(await client.get('/institutions', filters));
    },
    async getMyInstitutions(filters) {
        return client.parseResponse(await client.get('/my-institutions', filters));
    },
    async createInstitutionPageAdmin(payload) {
        return client.parseResponse(await client.post('/invitation', payload));
    },
    async updateInstitutionPageAdmin(payload) {
        return client.parseResponse(await client.put('/invitation/' + payload.id, payload));
    },
    async getInstitutionIntakes(payload) {
        return client.parseResponse(await client.get('/programme-intake', payload))
    }
}

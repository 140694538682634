import api from "../api";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        instruction_languages: []
    },
    getters: {
        instruction_languages: state => state.instruction_languages
    },
    mutations: {
        setInstructionLanguages(state, data) {
            state.instruction_languages = data;
        }
    },
    actions: {
        async getInstructionLanguages({commit}, filters) {
            try {
                const {data} = await api.getInstructionLanguages(filters);
                commit('setInstructionLanguages', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }
}

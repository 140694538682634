import client from "./client";
import store from "../store";

export default {
    async getApplications(payload){
        return client.parseResponse(await client.get('/application', payload))
    },
    async submitApplication(payload) {
        return client.parseResponse(await client.post('/application', payload))
    },
    async getProgrammeFinancialAids(filters){
        return client.parseResponse(await client.get('/financial-aid', filters));
    },
    async getProgrammeLevels(filters) {
        return client.parseResponse(await client.get('/programme-levels', filters));
    }, async createProgramme(payload) {
        return client.parseResponse(await client.post('/programmes', payload));
    }, async editProgramme(payload) {
        return client.parseResponse(await client.put('/programmes/' + payload.id, payload));
    }, async getProgrammes(filters) {
        const loggedIn = store.getters['profile/loggedIn'];
        return client.parseResponse(await client.get(loggedIn ? '/programme' : '/programmes', filters));
    }, async getSavedProgrammes(filters) {
        return client.parseResponse(await client.get('/saved-programme', filters));
    }, async getProgramme(payload) {
        const loggedIn = store.getters['profile/loggedIn'];
        return client.parseResponse(await client.get(loggedIn ? ('/auth/programme/' + payload.id) : ('/programmes/' + payload.id), payload));
    },
    async deleteProgramme(id) {
        return client.parseResponse(await client.delete('/programmes/' + id));
    },
    async updateProgrammeIntakeOption(payload){
        return client.parseResponse(await client.post('/programme/intake-option/' + payload.id,payload));
    },
    async favoriteCourse(payload) {
        return client.parseResponse(await client.post('/saved-programme', payload));
    },
    async createProgrammeIntake(payload) {
        return client.parseResponse(await client.post('/programme-intake', payload));
    },
    async createFinancialAid(payload) {
        return client.parseResponse(await client.post('/programme-financial-aid', payload));
    },
    async deleteProgrammeIntake(id) {
        return client.parseResponse(await client.delete('/programme-intake/' + id));
    }
}

<template>
    <footer class=" page-footer bg-green-base relative h-full">
        <button @click="openFeedbackForm('https://fourth-profile.typeform.com/to/zhWm6Cd2')"
            class="px-6 py-2 fixed feedbackButton"
            style="background-color: #000000;bottom: 170px;right: -65px;color: white; z-index: 50;">
            Give us feedback
        </button>

        <div class="container">
            <div class="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
                <div class="col-span-3 md:col-span-4">
                    <!-- Logo -->
                    <router-link to="/home" class="mb-6 inline-block">
                        <img src="@/assets/img/4p-logo-and-name-no-bg.svg" class="max-h-[6.25rem]" alt="FourthProfile Logo" />
                    </router-link>
                    <p class="text-white mb-12"> {{ $t("layout.footer.getInTouchLbl") }} </p>
                    <!-- Socials -->
                    <div class="flex space-x-5">
                        <a href="https://www.facebook.com/fourthprofile" class="group">
                            <i class="fa-brands fa-facebook group-hover:text-green-800  text-white text-xl"></i>
                        </a>
                        <a href="https://twitter.com/FourthProfile" class="group">
                            <i class="fa-brands fa-twitter group-hover:text-green-800  text-white text-xl"></i>
                        </a>
                        <a href="https://www.quora.com/profile/Fourth-Profile" class="group">
                            <i class="fa-brands fa-quora group-hover:text-green-800  text-white text-xl"></i>
                        </a>
                        <a href="https://www.instagram.com/fourthprofile/" class="group">
                            <i class="fa-brands fa-instagram group-hover:text-green-800  text-white text-xl"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UC2qqYXX3WkZ90G1P2t3l9Fg" class="group">
                            <i class="fa-brands fa-youtube group-hover:text-green-800  text-white text-xl"></i>
                        </a>
                        <a href="https://www.tiktok.com/@fourth_profile" class="group">
                            <i class="fa-brands fa-tiktok group-hover:text-green-800  text-white text-xl"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/fourth-profile-4p" class="group">
                            <i class="fa-brands fa-linkedin group-hover:text-green-800  text-white text-xl"></i>
                        </a>
                    </div>
                </div>
                <div class="col-span-full sm:col-span-4 md:col-span-3 md:col-start-6">
                    <h3 class="font-display text-green-50 mb-6 text-xl">{{ $t("layout.footer.quickLinksLbl") }}</h3>
                    <ul class="text-white flex flex-col space-y-1">
                        <li>
                            <router-link to="/about#colleges" class="hover:text-green-800">{{ $t("layout.footer.forCollegesAndUniversitiesLbl") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/about#students" class="hover:text-green-800">{{ $t("layout.footer.forStudentsAndProfessionalsLbl") }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/about#what-to-expect" class="hover:text-green-800">{{ $t("layout.footer.whatCanIExpectSoonLbl") }}</router-link>
                        </li>
                        <li>
                            <router-link to="/about#faq" class="hover:text-green-800">
                                {{ $t("layout.footer.faqsLbl") }}
                            </router-link>
                        </li>
                        <li>
                            <a href="https://blog.fourthprofile.com" target="_blank">
                                Blogs
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-span-full sm:col-span-3 md:col-span-2  md:col-start-9">
                    <h3 class="font-display text-green-50 mb-6 text-xl"> {{ $t("layout.footer.supportLbl") }}</h3>
                    <ul class="text-white flex flex-col space-y-1">
                        <li>
                            <a href="https://fourthprofile.tawk.help/" target="_blank" class="hover:text-green-800">{{ $t("layout.footer.helpCenterLbl") }}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-span-full sm:col-span-3 md:col-span-2">
                    <h3 class="font-display text-green-50 mb-6 text-xl">{{ $t("layout.footer.contactUsLbl") }}</h3>
                    <ul class="text-white flex flex-col space-y-1">
                        <li>
                            <a href="mailto:info@fourthprofile.com" class="hover:text-green-800">hello@fourthprofile.com</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0 border-t-green-300 border-t-[1px]">
          <span class="text-green-50 text-sm ">&copy;
              {{new Date().getFullYear()}} Fourth Profile. <a href="#" class="hover:text-green-800">{{ $t("layout.footer.allRightsReservedLbl") }} </a>
          </span>
                <ul class="text-green-50 flex flex-wrap space-x-4 text-sm">
                    <li>
                        <router-link to="/terms-of-use" class="hover:text-green-800">{{ $t("layout.footer.termsAndConditionsLbl") }}</router-link>
                    </li>
                    <li class="text-green-50"> • </li>
                    <li>
                        <router-link to="/privacy-policy" class="hover:text-green-800">{{ $t("layout.footer.privacyPolicyLbl") }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    data() {
        return {
            form:{
                name: "",
                email: "",
                option: "Request a feature",
                feedback: "",
            },
            options: [
                "Request a feature",
                "Report an error",
                "Other",
            ],
        };
    },
    methods:{
        openFeedbackForm(url) {
            window.open(url, '_blank').focus();
            // this.$router.push()
        },
    }
}
</script>

<style>
.feedbackButton {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.customZIndex{
    z-index: 40;
}
.customBorderRadius{
    border-radius: 15px;
}
</style>

export default [
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/policies/privacy-policy.vue'),
        },
        meta: {
            guest: true,
        }
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/policies/terms-of-use.vue'),
        },
        meta: {
            guest: true,
        }
    }

]

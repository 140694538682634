import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import profile from "./profile.store";
import notifications from "./notifications.store";
import institutions from "./institutions.store";
import faculties from "./faculties.store";
import delivery_modes from "./delivery_modes.store";
import delivery_timings from "./delivery_timings.store";
import disciplines from "./disciplines.store";
import instruction_languages from "./instruction_languages.store";
import programme_level_bases from "./programme_level_bases.store";
import programme_levels from "./programme_levels.store";
import analytics from "./analytics.store"

export default new Vuex.Store({
    state: {
        hide_menu: true,
        isOpen: false,
        router_loading: false,
        mobileMenu: false,
        onLine: navigator.onLine
    },
    getters: {
        hide_menu: state => state.hide_menu,
        isOpen: state => state.isOpen,
        router_loading: state => state.router_loading,
        mobileMenu: state => state.mobileMenu,
        onLine: state => state.onLine
    },
    mutations: {
        setHideMenu(state, status) {
            state.hide_menu = status;
        },
        setIsOpen(state, status) {
            state.isOpen = status;
        },
        setRouterLoading(state,status){
            state.router_loading = status
        },
        setMobileMenu(state,status){
            state.mobileMenu = status;
        },
        setOnlineStatus(state,status){
            state.onLine = status;
        }
    },
    actions: {},
    modules: {
        profile,
        notifications,
        institutions,
        faculties,
        delivery_modes,
        delivery_timings,
        disciplines,
        instruction_languages,
        programme_levels,
        programme_level_bases,
        analytics
    }
})

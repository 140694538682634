import client from "./client";

export default {
    async getNotificationSettings(filters) {
       return  await client.parseResponse(await client.get('/notification', filters));
    },
    async getFAQs(filters){
        return  await client.parseResponse(await client.get('/faq', filters));
    }
}

import api from "../api";
import router from "../../routes";
import Vue from "vue";
import client from "../api/client";
export default {
    namespaced: true,
    state: {
        user: {},
        message: '',
        errors: '',
        loading: false
    },
    getters: {
        loggedIn() {
            return !!window.localStorage.getItem('4p@token');
        },
        user: state => state.user,
        message: state => state.message,
        errors: state => state.errors,
        loading: state => state.loading
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setMessage(state, message) {
            state.message = message;
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
        setLoading(state,status){
            console.log(status)
            state.loading = status;
        }
    },
    actions: {
        async logout(context,filters){
            try {
               await api.logout(filters);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getProfile({commit}, filters) {
            try {
                const user = await api.getProfile(filters);
                commit('setUser', user);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async tourComplete(context,payload){
            return client.parseResponse(await client.post('/tour-complete',payload))
        },
        async updateProfile({dispatch, commit}, payload) {
            try {
                commit('setLoading',true)
                commit('setMessage', '')
                const {message} = await api.updateProfile(payload);
                dispatch('getProfile', {})
                Vue.$toast.open({
                    message: message,
                    type: 'success',
                    duration: 10000
                });
                commit('setLoading',false)
                await router.push('/courses');
            } catch (e) {
                commit('setLoading',false)
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async signUp({commit}, payload) {
            commit('setLoading',true)
            try {
                const response = await api.signUp(payload);
                window.amplitude.track('Sign Up')
                if (response.token){
                    localStorage.setItem('4p@token', response.token);
                    let redirect_url = localStorage.getItem('redirect_url');
                    window.amplitude.track('Sign In')
                    window.location.href = !response.user.pronoun ? '/setup-profile' : ( redirect_url ? redirect_url : '/courses');
                }else{
                    await router.push(`/verify-email?email=${payload.email}`)
                    Vue.$toast.open({
                        message: response.message,
                        type: 'success',
                        duration: 10000
                    });
                }
                commit('setLoading',false)
            } catch (e) {
                commit('setLoading',false)
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async googleSignIn(context, payload) {
            try {
                await api.googleSignIn(payload)
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async authenticate({commit}, payload) {
            commit('setLoading',true)
            try {
                commit('setMessage', '')
                const {token, user} = await api.authenticate(payload);
                localStorage.setItem('4p@token', token);
                let redirect_url = localStorage.getItem('redirect_url');
                commit('setLoading',false)
                window.amplitude.track('Sign In')
                window.location.href = !user.name ? '/setup-profile' : ( redirect_url ? redirect_url : '/courses');
                localStorage.removeItem('redirect_url')
            } catch (e) {
                if (e.response.status === 403){
                    Vue.$toast.open({
                        message: `Please verify your account. We've sent a code to ${payload.email}`,
                        type: 'warning',
                        duration: 10000
                    });
                    await router.push(`/verify-email?email=${payload.email}`)
                }else{
                    Vue.$toast.open({
                        message: e.response.data.message,
                        type: 'error',
                        duration: 10000
                    });
                }
                commit('setLoading',false)
            }
        },
        async resendVerifyEmail({commit}, payload) {
            try {
                commit('setMessage', '')
                await api.resendVerifyEmail(payload);
                Vue.$toast.open({
                    message: 'Verification code send successfully check your email.',
                    type: 'success',
                    duration: 10000
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async requestResetPassword(context, payload) {
            try {
                await api.requestResetPassword(payload);
                await router.push(`/reset?email=${payload.email}`)
                Vue.$toast.open({
                    message: 'We have sent a reset link to your email kindly check.',
                    type: 'success',
                    duration: 10000
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async setReminder(context, payload) {
            try {
                await api.setReminder(payload);
                Vue.$toast.open({
                    message: 'Thank you, we will keep reminding you to create a profile.',
                    type: 'success',
                    duration: 10000
                });
                await router.push('/sign-in');
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async reset({commit}, payload) {
            try {
                commit('setLoading',true)
                await api.reset(payload);
                Vue.$toast.open({
                    message: 'Your password has been changed successfully!.',
                    type: 'success',
                    duration: 10000
                });
                commit('setLoading',false)
                await router.push('/sign-in');
            } catch (e) {
                commit('setLoading',false)
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async sendEmailInvites(context,payload){
            return await api.sendEmailInvites(payload);
        },
        async verifyEmail(context,payload){
            return await api.verifyEmail(payload)
        }
    }
}

import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './data/store'
import './utils/firebase'
import '../src/assets/js/tailwind-elements'
import './assets/css/styles.css';
import 'element-ui/lib/theme-chalk/index.css';
import VeeValidate from 'vee-validate';
import {
    Collapse,
    CollapseItem,
    Pagination,
    Message,
    MessageBox,
    Loading,
    Dialog,
    Tabs,
    TabPane,
    Dropdown,
    DropdownItem,
    Slider,
    Select,
    Option
} from 'element-ui';
import VueI18n from 'vue-i18n'
import english from './locales/english.json'
import japanese from './locales/japanese.json'
import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
import JwPagination from 'jw-vue-pagination';
import VueTour from 'vue-tour';
import moment from 'moment';
require('vue-tour/dist/vue-tour.css')
Vue.prototype.moment = moment

Vue.use(VueTour)

Vue.use(VueMeta)
Vue.use(VueSocialSharing);
Vue.use(VueToast, {
    position: 'bottom-right'
});
Vue.use(VueI18n)
Vue.component(Collapse.name,Collapse)
Vue.component(CollapseItem.name,CollapseItem)
Vue.component(Pagination.name,Pagination)
Vue.component(Select.name,Select)
Vue.component(Option.name,Option)
Vue.component(Dialog.name, Dialog);
Vue.component(TabPane.name, TabPane);
Vue.component(Tabs.name, Tabs);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(Slider.name, Slider)
Vue.component('jw-pagination', JwPagination);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$ELEMENT = {size: 'medium', zIndex: 3000};
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang);
Vue.use(VeeValidate);
import {Validator} from 'vee-validate';

Validator.extend('contain_lowercase', {
    getMessage: field => 'The ' + field + ' must contain at least one lowercase letter',
    validate: (value) => {
        return /[a-z]/.test(value);
    }
});
Validator.extend('contain_uppercase', {
    getMessage: field => 'The ' + field + ' must contain at least one uppercase letter',
    validate: (value) => {
        return /[A-Z]/.test(value)
    }
});
Validator.extend('contain_number', {
    getMessage: field => 'The ' + field + ' must contain at least one digit',
    validate: (value) => {
        return /[0-9]/.test(value);
    }
});
Validator.extend('contain_special_character', {
    getMessage: field => 'The ' + field + ' must contain at least one special character',
    validate: (value) => {
        return /[@$!%*#?&.]/.test(value);
    }
});
Validator.extend('valid_website_url', {
    getMessage: field => 'The ' + field + ' must be a valid url',
    validate: (value) => {
        return /^(http|https):\/\/+[\www\d]+\.[\w]+(\/[\w\d]+)?/.test(value);
    }
});
Vue.mixin({
    methods: {
        //eslint-disable-line
        toChar(id){
            return this.getCharValue(4) + this.getIntValue('p') + this.getCharValue(id) + this.getIntValue('mvp');
        },
        getCharValue(int){
            const code = 'a'.charCodeAt(0);
            return String.fromCharCode(code + int)
        },
        getIntValue(char){
            const code = 'a'.charCodeAt(0);
            return [...char].reduce((i,f)=>{
                return i+ (f.charCodeAt(0) - code);
            },'')
        },
        toId(str){
            // let array = [];
            let index  = 0;
            let intChar = null;
            [...str].forEach((item)=>{
               if (isNaN(item)){
                   if (index === 1){
                       intChar = item
                   }
                   index++
               }
            })
            return this.getIntValue(intChar);
        }
    }
})
Vue.config.productionTip = false

// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
//
// Vue.use(TawkMessengerVue, {
//     propertyId : 'property_id',
//     widgetId : 'widget_id'
// });
// Ready translated locale messages
const messages = {
    en: english,
    ja: japanese
}
let localeValue = localStorage.getItem('language') !== undefined ? localStorage.getItem('language') : 'en'
// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: localeValue, // set locale
    messages, // set locale messages
})
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});
new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

import api from "../api";
import router from "../../routes";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        programme: {
            delivery_modes: [],
            delivery_timing_modes: [],
            institution: {},
            accreditations: [],
            admission_requirements: [],
            faculty: {
                institution: {}
            }
        },
        financial_aids: [],
        applications: {data: [], total: 0, per_page: 10},
        programme_levels: [],
        programmes: {data: [], total: 0, per_page: 10},
        loading: false,
        saved_programmes: {data: [], total: 0, per_page: 10}
    }, getters: {
        programme_levels: state => state.programme_levels,
        programmes: state => state.programmes,
        programme: state => state.programme,
        loading: state => state.loading,
        saved_programmes: state => state.saved_programmes,
        applications: state => state.applications,
        financial_aids: state => state.financial_aids
    }, mutations: {
        setSavedProgrammes(state, data) {
            state.saved_programmes = data;
        }, setProgrammeLevels(state, data) {
            state.programme_levels = data;
        }, setProgrammes(state, data) {
            state.programmes = data;
        }, setProgramme(state, data) {
            state.programme = data;
        }, setLoading(state, status) {
            state.loading = status;
        },
        setApplications(state, data) {
            state.applications = data;
        },
        setFinancialAids(state,data){
            state.financial_aids = data;
        }
    }, actions: {
        async submitApplication(context, payload) {
            return await api.submitApplication(payload);
        },
        async getApplications({commit}, payload) {
            try {
                commit('setLoading', true);
                const response = await api.getApplications(payload)
                commit('setApplications', response)
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
            }
        },
        async getProgramme({commit}, payload) {
            try {
                const {data} = await api.getProgramme(payload);
                commit('setProgramme', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        },
        async getProgrammes({commit}, filters) {
            try {
                if (!filters.load_more) {
                    commit('setLoading', true);
                }
                const {data} = await api.getProgrammes(filters);
                commit('setProgrammes', data);
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        }, async getSavedProgrammes({commit}, filters) {
            try {
                const response = await api.getSavedProgrammes(filters);
                commit('setSavedProgrammes', response)
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        }, async favoriteCourse({dispatch}, payload) {
            try {
                const {message} = await api.favoriteCourse(payload);
                dispatch('getProgrammes', {
                    paginate: true,
                    continents: [],
                    countries: [],
                    programme_levels: [],
                    disciplines: [],
                    instruction_languages: [],
                    delivery_modes: [],
                    delivery_timings: [],
                    programme_level_bases: []
                });
                let textHtml = `<p class="flex flex-row items-center justify-center">${message}  <a href="javascript:void(0)" class="text-[#fff]">&nbsp;View All</a></p>`
                Vue.$toast.open({
                    message: textHtml, type: 'success', duration: 10000, onClick: async () => {
                        await router.push('/profile?tab=savedCourseTab')
                    }
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        },
        async getProgrammeFinancialAids({commit}, filters) {
            try {
                const response = await api.getProgrammeFinancialAids(filters);
                commit('setFinancialAids', response);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        },
        async getProgrammeLevels({commit}, filters) {
            try {
                const {data} = await api.getProgrammeLevels(filters);
                commit('setProgrammeLevels', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        }, async createProgramme({commit}, filters) {
            try {
                commit('setLoading', true)
                await api.createProgramme(filters);
                commit('setLoading', false)
                await router.push('/institutions/' + filters.public_url)
                Vue.$toast.open({
                    message: 'Programme added successfully!', type: 'success', duration: 10000
                });
            } catch (e) {
                commit('setLoading', false)
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        }, async editProgramme({commit}, filters) {
            commit('setLoading', true)
            try {
                await api.editProgramme(filters);
                commit('setLoading', false)
                await router.push('/institutions/' + filters.public_url)
                Vue.$toast.open({
                    message: 'Programme updated successfully!', type: 'success', duration: 10000
                });
            } catch (e) {
                commit('setLoading', false)
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        },
        async deleteProgramme(context, payload) {
            try {
                await api.deleteProgramme(payload.id);
                Vue.$toast.open({
                    message: 'Programme deleted successfully!', type: 'success', duration: 10000
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message, type: 'error', duration: 10000
                });
            }
        },
        async updateProgrammeIntakeOption(context,payload){
          return await api.updateProgrammeIntakeOption(payload)
        },
        async deleteProgrammeIntake(context, id) {
            return await api.deleteProgrammeIntake(id);
        },
        async createProgrammeIntake(context, payload) {
            return await api.createProgrammeIntake(payload);
        },
        async createFinancialAid(context, payload) {
            return await api.createFinancialAid(payload);
        }

    }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './auth.routes';
import dashboard from './dashboard.routes';
import institutions from "./institutions.routes";
import faculties from "./faculties.routes";
import programmes from "./programmes.routes";
import policies from "./policies.routes";
import authMiddleware from './middlewares/auth-middleware';
import store from "../data/store";
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    routes: [
        ...auth,
        ...dashboard,
        ...institutions,
        ...faculties,
        ...programmes,
        ...policies,
        {
            path: '*',
            redirect: '/not-found',
        }
    ]
})
router.beforeEach((to, from, next) => {
    store.commit('setHideMenu',true);
    store.commit('setIsOpen',false);
    store.commit('setMobileMenu',false);
    store.commit('profile/setMessage', '');
    window.scrollTo(0,0);
    let result = next();
    if (to.meta.auth || to.meta.redirect_if_authenticated) {
        result = authMiddleware(to, next);
    }
    return result;
});

export default router

import client from "./client";

export default {
    async logout(filters) {
        return client.parseResponse(await client.post('/logout', filters))
    },
    async getProfile(filters) {
        return client.parseResponse(await client.get('/profile', filters))
    },
    async setReminder(payload) {
        return client.parseResponse(await client.post('/reminder', payload))
    },
    async updateProfile(payload) {
        return client.parseResponse(await client.post('/profile', payload))
    },
    async signUp(payload) {
        return client.parseResponse(await client.post('/register', payload))
    },
    async authenticate(payload) {
        return client.parseResponse(await client.post('/login', payload))
    },
    async googleSignIn(payload) {
        return client.parseResponse(await client.post('/google-sign-in', payload))
    },
    async resendVerifyEmail(payload) {
        return client.parseResponse(await client.post('/email/resend', payload))
    },
    async verifyEmail(payload) {
        return client.parseResponse(await client.post('/email/verify', payload))
    },
    async requestResetPassword(payload) {
        return client.parseResponse(await client.post('/forgot-password', payload))
    },
    async reset(payload) {
        return client.parseResponse(await client.post('/reset', payload))
    },
    async sendEmailInvites(payload) {
        return client.parseResponse(await client.post('/invite-users', payload))
    }
}

export default [
    {
        path: '/courses/create/:public_url/:id?',
        name: 'programmes-create',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/programmes/create-programme'),
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/courses/:faculty/:programme/:id',
        name: 'view-program',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/programmes/programme'),
        },
        meta: {
            guest: true,
        },
    },
    {
        path: '/courses',
        name: 'view-programmes',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/programmes/programmes-list'),
        },
        meta: {
            guest: true,
            description: 'Fourth Profile has a catalog of thousands of courses from many universities and colleges across the world. You have access to many filters to narrow down your search to favourite courses. Discover courses with open intakes and those with scholarships',
        },
    },
    {
        path: '/institutions/courses/intake/:id',
        name: 'create-intakes',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/institutions/create-intake'),
        },
        meta: {
            auth: true,
            admin: true
        },
        props: true
    },
]

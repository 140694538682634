export default [
    {
        path: '/institution/create',
        name: 'institution-create',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/institutions/create-institution'),
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/institution/edit/:public_url',
        name: 'institution-edit',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/institutions/edit-institution'),
        },
        meta: {
            auth: true,
            admin: true
        },
    },
    {
        path: '/institutions/:public_url',
        name: 'institution',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/institutions/institution-profile'),
        },
        meta: {
            guest: true,
        },
        props: true
    },
    {
        path: '/accept/invitation/:token/:public_url/:email',
        name: 'accept-invitation',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/institutions/accept-invitation'),
        },
        meta: {
            guest: true,
        }
    },
    {
        path: '/institutions',
        name: 'view-institutions',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/institutions/institutions-list'),
        },
        meta: {
            guest: true,
            description: 'Use Fourth Profile to discover thousands of universities and colleges across the world. Fourth Profile has the most updated database of the best colleges globally. Discover colleges offering scholarships and those with tuition free courses',

        },
    },
    {
        path: '/institution/manage/:public_url',
        name: 'manage-admins',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/institutions/manage-admins'),
        },
        meta: {
            auth: true,
            admin: true
        },
        props: true
    },

]

import client from "./client";

export default {
    async recordView(payload){
        return client.parseResponse(await client.post('/analytic',payload))
    },
    async getAnalytics(payload){
        return client.parseResponse(await client.get('/analytic',payload))
    }
}

import api from "../api";
import router from "../../routes";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        institution_types: [],
        industries: [],
        countries: [],
        continents: [],
        my_institutions: [],
        institution: {
            page_admins: [],
            institution_locations: []
        },
        institutions: {data: [], total: 0, per_page: 25},
        institution_locations: [],
        loading: false,
        institution_record_count: 0,
        error_message: null,
        message: null,
        institution_intakes: [],
        institutions_i_follow: [],
        financial_aids: []
    },
    getters: {
        message: state => state.message,
        error_message: state => state.error_message,
        continents: state => state.continents,
        industries: state => state.industries,
        countries: state => state.countries,
        institution: state => state.institution,
        institutions: state => state.institutions,
        my_institutions: state => state.my_institutions,
        institution_locations: state => state.institution_locations,
        loading: state => state.loading,
        institution_record_count: state => state.institution_record_count,
        institution_intakes: state => state.institution_intakes,
        institutions_i_follow: state => state.institutions_i_follow,
        financial_aids: state => state.financial_aids,
        institution_types: state => state.institution_types
    },
    mutations: {
        setInstitutionTypes(state,data){
          state.institution_types = data;
        },
        setInstitutionsIFollow(state,data){
            state.institutions_i_follow = data;
        },
        setMessage(state, message) {
            state.message = message;
        },
        setErrorMessage(state, message) {
            state.error_message = message;
        },
        setIndustries(state, industries) {
            state.industries = industries;
        },
        setCountries(state, countries) {
            state.countries = countries;
        },
        setContinents(state, continents) {
            state.continents = continents;
        },
        setInstitution(state, institution) {
            state.institution = institution
        },
        setInstitutions(state, data) {
            state.institutions = data;
        },
        setMyInstitutions(state, data) {
            state.my_institutions = data;
        },
        setInstitutionLocations(state, data) {
            state.institution_locations = data;
        },
        setLoading(state, status) {
            state.loading = status;
        },
        setInstitutionRecordCount(state, count) {
            state.institution_record_count = count;
        },
        setInstitutionIntakes(state,data){
            state.institution_intakes = data;
        },
        setFinancialAids(state,data){
            state.financial_aids = data;
        }
    },
    actions: {
        async checkInstitutionExists({commit}, filters) {
            try {
                const {record_count} = await api.checkInstitutionExists(filters);
                commit('setInstitutionRecordCount', record_count);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getInstitutionsIFollow({commit},filters){
            try {
                const data = await api.getInstitutionsIFollow(filters);
                commit('setInstitutionsIFollow', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getIndustries({commit}, filters) {
            try {
                const {data} = await api.getIndustries(filters);
                commit('setIndustries', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getInstitutionTypes({commit}, filters) {
            try {
                const response = await api.getInstitutionTypes(filters);
                commit('setInstitutionTypes', response);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getInstitutionLocations({commit}, filters) {
            try {
                const {data} = await api.getInstitutionLocations(filters);
                commit('setInstitutionLocations', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async acceptInvitation({commit}, payload) {
            try {
                const {message} = await api.acceptInvitation(payload)
                commit('setMessage', message)
            } catch (e) {
                commit('setErrorMessage', e.response.data.message)
            }
        },
        async deleteInstitution(context, payload) {
            try {
                await api.deleteInstitution(payload);
                await router.push('/institutions')
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async createInstitutionPageAdmin({commit, dispatch}, payload) {
            commit('setErrorMessage', null)
            try {
                await api.createInstitutionPageAdmin(payload);
                dispatch('getInstitution',{
                    id: payload.public_url
                })
            } catch (e) {
                commit('setErrorMessage', e.response.data.message)
            }
        },
        async updateInstitutionPageAdmin({commit, dispatch}, payload) {
            commit('setErrorMessage', null)
            try {
                await api.updateInstitutionPageAdmin(payload);
                dispatch('getInstitution',{
                    id: payload.public_url
                })
                Vue.$toast.open({
                    message: 'Admin role updated successfully!',
                    type: 'success',
                    duration: 10000
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async removeInstitutionPageAdmin(context, payload) {
            try {
                await api.removeInstitutionPageAdmin(payload);
                Vue.$toast.open({
                    message: 'Admin removed successfully!',
                    type: 'success',
                    duration: 10000
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getInstitutionIntakes({commit},payload){
          try {
             const response = await api.getInstitutionIntakes(payload);
             commit('setInstitutionIntakes',response)
          }catch (e) {
              Vue.$toast.open({
                  message: e.response.data.message,
                  type: 'error',
                  duration: 10000
              });
          }
        },
        async createInstitutionClaim(context,payload){
          return await api.createInstitutionClaim(payload);
        },
        async getInstitution({commit}, payload) {
            try {
                const {data} = await api.getInstitution(payload);
                commit('setInstitution', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async followInstitution({dispatch}, institution) {
            try {
                let {message} = await api.followInstitution(institution);
                dispatch('getInstitution',{
                    id: institution.public_url
                })
                Vue.$toast.open({
                    message: message,
                    type: 'success',
                    duration: 10000
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getInstitutions({commit}, filters) {
            try {
                if (!filters.load_more) {
                    commit('setLoading', true);
                }
                const {data} = await api.getInstitutions(filters);
                commit('setInstitutions', data);
                commit('setLoading', false);
            } catch (e) {
                commit('setLoading', false);
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getMyInstitutions({commit}, filters) {
            try {
                const {data} = await api.getMyInstitutions(filters);
                commit('setMyInstitutions', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getCountries({commit}, filters) {
            try {
                const {data} = await api.getCountries(filters);
                commit('setCountries', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getFinancialAids({commit},payload){
          try {
              const response = await api.getFinancialAids(payload);
              commit('setFinancialAids',response)
          }catch (e) {
              Vue.$toast.open({
                  message: e.response.data.message,
                  type: 'error',
                  duration: 10000
              });
          }
        },
        async createAdmissionEmails(context,payload){
          return await api.createAdmissionEmails(payload);
        },
        async deleteFinancialAid(context,id){
          return await api.deleteFinancialAid(id);
        },
        async updateFinancialAid({dispatch},payload){
            try {
                await api.updateFinancialAid(payload);
                dispatch('getFinancialAids',{
                    institution_id: payload.institution_id
                })
            }catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getContinents({commit}, filters) {
            try {
                const {data} = await api.getContinents(filters);
                commit('setContinents', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async createInstitution({commit}, payload) {
            try {
                commit('setLoading',true)
                const {data} = await api.createInstitution(payload);
                Vue.$toast.open({
                    message: 'Institution added successfully!',
                    type: 'success',
                    duration: 10000
                });
                commit('setLoading',false)
                await router.push('/institutions/' + data.public_url)
            } catch (e) {
                commit('setLoading',false)
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async updateInstitution({commit}, payload) {
            try {
                commit('setLoading',true)
                const {data} = await api.updateInstitution(payload);
                this._vm.$message.success('Institution updated successfully!');
                commit('setLoading',false)
                await router.push('/institutions/' + data.public_url)
            } catch (e) {
                commit('setLoading',false)
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }
}

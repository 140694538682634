import store from '../../data/store';

const aclMiddleware = (to, next) => {
    setTimeout(() => {
        const loggedIn = store.getters['profile/loggedIn'];
        const user = store.getters['profile/user'];
        if (!loggedIn && !to.meta.redirect_if_authenticated) {
            window.location.href = '/sign-in';
            return next(false);
        }
        if (loggedIn && !to.meta.redirect_if_authenticated && !user.name && to.path !== '/setup-profile') {
            window.location.href = '/setup-profile';
            return next(false);
        }
        if (loggedIn && to.meta.redirect_if_authenticated) {
            window.location.href = '/courses';
        }
        return next();
    }, 2000);
    return next();
};

export default aclMiddleware;

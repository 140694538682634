import {initializeApp} from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyCZqMbpoGFge2DYMYT8TBGSs5Oj3CYD_PY",
    authDomain: "fourthprofile.firebaseapp.com",
    projectId: "fourthprofile",
    storageBucket: "fourthprofile.appspot.com",
    messagingSenderId: "701061987832",
    appId: "1:701061987832:web:e2f5403ce2c9fe7eceea0d",
    measurementId: "G-2TZHXW516Z"
};
initializeApp(firebaseConfig)

import api from "../api";
import router from "../../routes";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        faculties: {data: [], total: 0, per_page: 25},
        faculty: {},
        loading: false
    },
    getters: {
        faculties: state => state.faculties,
        faculty: state => state.faculty,
        loading: state => state.loading
    },
    mutations: {
        setFaculties(state, data) {
            state.faculties = data;
        },
        setFaculty(state, data) {
            state.faculty = data;
        },
        setLoading(state,status){
            state.loading = status
        }
    },
    actions: {
        async getFaculties({commit}, filters) {
            try {
                const {data} = await api.getFaculties(filters);
                commit('setFaculties', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async getFaculty({commit}, id) {
            try {
                const {data} = await api.getFaculty(id);
                commit('setFaculty', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async createFaculty({commit}, payload) {
            commit('setLoading',true)
            try {
                await api.createFaculty(payload);
                Vue.$toast.open({
                    message: 'Faculty added successfully',
                    type: 'success',
                    duration: 10000
                });
                commit('setLoading',false)
                await router.push('/institutions/' + payload.public_url)
            } catch (e) {
                commit('setLoading',false)
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async updateFaculty({commit}, payload) {
            commit('setLoading',true)
            try {
                await api.updateFaculty(payload);
                Vue.$toast.open({
                    message: 'Faculty updated successfully',
                    type: 'success',
                    duration: 10000
                });
                commit('setLoading',false)
                await router.push('/institutions/' + payload.public_url)
            } catch (e) {
                commit('setLoading',false)
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        },
        async deleteFaculty({dispatch}, payload) {
            try {
                await api.deleteFaculty(payload.id);
                dispatch('getFaculties', payload);
                Vue.$toast.open({
                    message: 'Faculty deleted successfully',
                    type: 'success',
                    duration: 10000
                });
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }

}

<template>
    <header
        class="js-page-header fixed top-0 z-[6000] w-full transition-colors js-page-header--top h-18"
    >
        <div
            v-if="!onLine"
            class="flex items-center justify-center w-full bg-red text-white h-1/8 p-2"
        >
            {{ $t("noInternetConnectionLbl") }}
            <i class="fa fa-exclamation ml-2 text-white" aria-hidden="true"></i>
        </div>
        <!-- <div class="flex items-center px-6 py-4 xl:px-24 container"> -->
        <div class="flex items-center px-6 py-4 container">
            <!-- Main Logo -->
            <router-link to="/" class="shrink-0">
                <!-- White logo displayed at top of page -->
                <img
                    src="@/assets/img/4p-logo-and-name-no-bg.svg"
                    id="main-logo"
                    class="main-logo max-h-12 md:max-h-14"
                    alt="FourthProfile Logo"
                />
                <!-- Coloured logo displayed when user scrolls -->
                <img
                    src="@/assets/img/4p-logo-and-name-colored-no-bg.svg"
                    id="logo-dark"
                    class="hidden max-h-12 md:max-h-14"
                    alt="FourthProfile Logo"
                />
            </router-link>
            <!-- Menu / Actions -->
            <div
                class="js-mobile-menu invisible fixed inset-0 z-10 ml-auto items-center bg-green-100 opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100"
                v-bind:class="{ 'nav-menu--is-open': mobileMenu }"
            >
                <!-- Mobile Logo / Menu Close -->
                <div
                    class="t-0 fixed left-0 z-10 flex w-full items-center justify-between bg-green-100 p-6 lg:hidden"
                >
                    <!-- Mobile Logo -->
                    <router-link to="/" class="shrink-0">
                        <img
                            src="@/assets/img/4p-logo-and-name-colored-no-bg.svg"
                            class="max-h-12 md:max-h-14"
                            alt="FourthProfile Logo"
                        />
                    </router-link>
                    <!-- Mobile Menu Close Btn-->
                    <button
                        @click="$store.commit('setMobileMenu', false)"
                        class="js-mobile-close border-green-100 hover:bg-accent focus:bg-accent group ml-2 flex h-12 w-12 rounded-lg items-center justify-center transition-colors hover:border-transparent focus:border-transparent"
                    >
                        <i
                            class="fa-solid fa-xmark text-green-700 h-10 w-10 text-4xl transition-colors group-hover:text-white group-focus:text-white"
                        ></i>
                    </button>
                </div>
                <!-- Empty Section for padding purposes on the mobile navbar dropdown (only visible on mobile) -->
                <div class="mt-12 mb-8 lg:hidden">
                    <div class="py-6 px-4"></div>
                </div>
                <!-- End of empty div-->
                <!-- Primary Nav -->
                <nav class="navbar w-full">
                    <ul class="flex flex-col lg:flex-row lg:mt-0">
                        <!--                        <li class="group">-->
                        <!--                            <select v-model="language" class="text-green-800 bg-transparent nav-text font-display hover:text-green-800 flex items-center justify-between py-3.5 text-base lg:px-2">-->
                        <!--                                <option value="">{{$t('selectLanguageLbl')}}</option>-->
                        <!--                                <option value="en">English</option>-->
                        <!--                                <option value="ja">Japanese</option>-->
                        <!--                            </select>-->
                        <!--                        </li>-->
                        <!-- Nav Link 1 -->
                        <li class="group" v-if="!loggedIn">
                            <!-- How it works button & modal -->
                            <button class="nav-text font-display hover:text-green-800 flex items-center justify-between py-3.5 text-base lg:px-5"
                                @click="openHowItWorksModal">
                                    {{ $t("dashboard.home.howItWorksLbl") }}
                            </button>

                            <dialog id="howItWorksModal" class="w-full container p-5 bg-white rounded-md ">
                                <!-- Header -->
                                <div class="flex w-full h-auto items-center justify-between mt-2">
                                    <h1 class="text-xl font-semibold ml-12">{{
                                            $t('dashboard.home.modalHeaderLbl')
                                        }}</h1>
                                    <div onclick="document.getElementById('howItWorksModal').close();"
                                            class="flex w-1/12 h-auto justify-center cursor-pointer mt-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="#000000" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-x">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </div>
                                </div>

                                <!-- Content -->
                                <div class="w-full h-5/6 mt-5 md:px-7 grid grid-cols-1 lg:grid-cols-2 gap-7">
                                    <!-- For students -->
                                    <div class="w-full flex flex-col relative howItWorksVerticalLine">
                                        <h2 class="text-green-700 mb-6 text-lg font-semibold">
                                            {{ $t('dashboard.home.forStudentsLbl') }}
                                        </h2>

                                        <p class=" mb-10 text-base md:text-sm leading-normal">{{ $t('dashboard.about.titleLbl1') }} </p>
                                        <h3 class="text-black font-display mb-6 text-xl"> {{ $t('dashboard.about.titleLbl2') }} </h3>

                                        <div class="grid grid-cols-1 gap-3">
                                            <div
                                                class="border-green-100  rounded-lg flex border  bg-green-50 py-4 px-7 transition-shadow ">
                                                <figure class="mr-4 shrink-0">
                                                    <a href="#" class="relative block">
                                                        <img src="@/assets/img/avatars/Create Profile.png" alt="avatar 1"
                                                            class="rounded-2lg" loading="lazy"/>
                                                        <div
                                                            class=" bg-green-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-green-50 text-xs text-white">
                                                            1
                                                        </div>
                                                        <div
                                                            class=" bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full bg-green-800 border-2 border-green-50"
                                                            data-tippy-content="Verified Collection">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                                height="24" class="h-[.875rem] w-[.875rem] fill-white">
                                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                                <path
                                                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </figure>
                                                <div>
                                                    <router-link :to="loggedIn ? '/courses': '/sign-up'" class="block">
                                                        <span class="hover:text-accent font-display text-green-700 font-bold">
                                                            {{ $t('dashboard.about.titleLbl3') }}
                                                        </span>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div
                                                class="border-green-100  rounded-lg flex border  bg-green-50 py-4 px-7 transition-shadow ">
                                                <figure class="mr-4 shrink-0">
                                                    <a href="#" class="relative block">
                                                        <img src="@/assets/img/avatars/Search.png" alt="avatar 1" class="rounded-2lg"
                                                            loading="lazy"/>
                                                        <div
                                                            class=" bg-green-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-green-50 text-xs text-white">
                                                            2
                                                        </div>
                                                        <div
                                                            class=" bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full bg-green-800 border-2 border-green-50"
                                                            data-tippy-content="Verified Collection">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                                height="24" class="h-[.875rem] w-[.875rem] fill-white">
                                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                                <path
                                                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </figure>
                                                <div>
                                                    <router-link to="/courses" class="block">
                                                        <span
                                                            class="hover:text-accent font-display text-green-700 font-bold">{{ $t('dashboard.about.titleLbl4') }} </span>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- For college reps -->
                                    <div class="w-full flex flex-col">
                                        <h2 class="text-green-700 mb-6 text-lg font-semibold">
                                            {{ $t('dashboard.home.forUniRepsLbl') }}
                                        </h2>

                                        <p class=" mb-10 text-sm leading-normal"> {{ $t('dashboard.about.titleLbl6') }} </p>
                                        <h3 class="text-black font-display mb-6 text-xl"> {{ $t('dashboard.about.titleLbl2') }}</h3>
                                        <div class="grid grid-cols-1 gap-3">
                                            <div
                                                class="border-green-100  rounded-lg flex border  bg-green-100 py-4 px-7 transition-shadow ">
                                                <figure class="mr-4 shrink-0">
                                                    <a href="#" class="relative block">
                                                        <img src="@/assets/img/avatars/Create Profile.png" alt="avatar 1"
                                                            class="rounded-2lg" loading="lazy"/>
                                                        <div
                                                            class=" bg-green-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-green-50 text-xs text-white">
                                                            1
                                                        </div>
                                                        <div
                                                            class=" bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full bg-green-800 border-2 border-green-50"
                                                            data-tippy-content="Verified Collection">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                                height="24" class="h-[.875rem] w-[.875rem] fill-white">
                                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                                <path
                                                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </figure>
                                                <div>
                                                    <router-link :to="loggedIn ? '/courses': '/sign-up'" class="block">
                                                        <span
                                                            class="hover:text-accent font-display text-green-700 font-bold">{{ $t('dashboard.about.titleLbl3') }}</span>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div
                                                class="border-green-100  rounded-lg flex border  bg-green-100 py-4 px-7 transition-shadow ">
                                                <figure class="mr-4 shrink-0">
                                                    <a href="#" class="relative block">
                                                        <img src="@/assets/img/avatars/add.png" alt="avatar 1" class="rounded-2lg"
                                                            loading="lazy"/>
                                                        <div
                                                            class=" bg-green-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-green-50 text-xs text-white">
                                                            2
                                                        </div>
                                                        <div
                                                            class=" bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full bg-green-800 border-2 border-green-50"
                                                            data-tippy-content="Verified Collection">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                                height="24" class="h-[.875rem] w-[.875rem] fill-white">
                                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                                <path
                                                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </figure>
                                                <div>
                                                    <router-link :to="loggedIn ? '/courses': '/sign-up'" class="block">
                                                        <span
                                                            class="md:text-sm  hover:text-accent font-display text-green-700 font-bold">{{ $t('dashboard.about.titleLbl2_1') }} </span>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div
                                                class="border-green-100  rounded-lg flex border  bg-green-100 py-4 px-7 transition-shadow ">
                                                <figure class="mr-4 shrink-0">
                                                    <a href="#" class="relative block">
                                                        <img src="@/assets/img/avatars/list.png" alt="avatar 1" class="rounded-2lg"
                                                            loading="lazy"/>
                                                        <div
                                                            class=" bg-green-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-green-50 text-xs text-white">
                                                            3
                                                        </div>
                                                        <div
                                                            class=" bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full bg-green-800 border-2 border-green-50"
                                                            data-tippy-content="Verified Collection">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                                height="24" class="h-[.875rem] w-[.875rem] fill-white">
                                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                                <path
                                                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </figure>
                                                <div>
                                                    <router-link to="/courses" class="block">
                                                        <span
                                                            class="hover:text-accent font-display text-green-700 font-bold">{{ $t('dashboard.about.titleLbl8') }}</span>
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div
                                                class="border-green-100  rounded-lg flex border  bg-green-100 py-4 px-7 transition-shadow ">
                                                <figure class="mr-4 shrink-0">
                                                    <a href="#" class="relative block">
                                                        <img src="@/assets/img/avatars/recruit.png" alt="avatar 1" class="rounded-2lg"
                                                            loading="lazy"/>
                                                        <div
                                                            class=" bg-green-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-green-50 text-xs text-white">
                                                            4
                                                        </div>
                                                        <div
                                                            class=" bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full bg-green-800 border-2 border-green-50"
                                                            data-tippy-content="Verified Collection">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                                                height="24" class="h-[.875rem] w-[.875rem] fill-white">
                                                                <path fill="none" d="M0 0h24v24H0z"/>
                                                                <path
                                                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"/>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </figure>
                                                <div>
                                                    <a href="#" class="block">
                                                        <span
                                                            class="md:text-sm hover:text-accent font-display text-green-700 font-bold">{{ $t('dashboard.about.titleLbl9') }}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="w-full mt-10 flex flex-row justify-center">
                                    <router-link :to="loggedIn ? '/courses': '/sign-up'" target="_blank"
                                                rel="noreferrer noopener"
                                                class="bg-accent  hover:bg-accent-dark rounded-lg py-3 px-8 text-center font-semibold text-white transition-all">
                                        {{ $t('dashboard.about.getStartedLbl') }}
                                    </router-link>
                                </div>
                            </dialog>
                        </li>
                        <li class="group">
                            <router-link
                                to="/institutions"
                                class="nav-text font-display hover:text-green-800 flex items-center justify-between py-3.5 text-base lg:px-5"
                            >
                                {{ $t("layout.content.findAnInstitutionLbl") }}
                            </router-link>
                        </li>
                        <!-- Nav Link 2 -->
                        <li class="group">
                            <router-link
                                to="/courses"
                                class="nav-text font-display hover:text-green-800 flex items-center justify-between py-3.5 text-base lg:px-5"
                            >
                                {{ $t("layout.content.findACourseLbl") }}
                            </router-link>
                        </li>

                        <!-- When a user logs in hide this list-->
                        <li v-if="!loggedIn" class="group mt-10 lg:mt-0">
                            <div
                                class="flex space-x-4 justify-center items-center lg:justify-start"
                            >
                                <!-- User Authentication Logic Start -->
                                <!-- SignIn -->
                                <router-link
                                    to="/sign-in"
                                    class="nav-btn-outline w-auto border-2 rounded-[8px] py-3 px-8 text-center font-semibold transition-all"
                                >
                                    {{ $t("layout.content.signInLbl") }}
                                </router-link>
                                <!-- Register -->
                                <router-link
                                    to="/sign-up"
                                    class="bg-green-800 border-green-800 w-auto rounded-[8px] border-2 py-3 px-8 text-center font-semibold text-white hover:text-green-200 transition-all"
                                >
                                    {{ $t("layout.content.joinForFreeLbl") }}
                                </router-link>
                                <!-- User Authentication Logic Start -->
                            </div>
                        </li>
                        <!-- End of list -->
                    </ul>
                </nav>

                <!-- Mobile FourthProfile Socials (optional) -->
                <div class="mt-10 w-full lg:hidden">
                    <!-- Socials -->
                    <div class="flex items-center justify-center space-x-5">
                        <a
                            href="https://www.facebook.com/fourthprofile"
                            class="group"
                        >
                            <i
                                class="fa-brands fa-facebook group-hover:text-green-800 text-green-800 text-xl"
                            ></i>
                        </a>
                        <a
                            href="https://twitter.com/FourthProfile"
                            class="group"
                        >
                            <i
                                class="fa-brands fa-twitter group-hover:text-green-800 text-green-800 text-xl"
                            ></i>
                        </a>
                        <a
                            href="https://www.quora.com/profile/Fourth-Profile"
                            class="group"
                        >
                            <i
                                class="fa-brands fa-quora group-hover:text-green-800 text-green-800 text-xl"
                            ></i>
                        </a>
                        <a
                            href="https://www.instagram.com/fourthprofile/"
                            class="group"
                        >
                            <i
                                class="fa-brands fa-instagram group-hover:text-green-800 text-green-800 text-xl"
                            ></i>
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UC2qqYXX3WkZ90G1P2t3l9Fg"
                            class="group"
                        >
                            <i
                                class="fa-brands fa-youtube group-hover:text-green-800 text-green-800 text-xl"
                            ></i>
                        </a>
                    </div>
                </div>
            </div>
            <!-- When a user logs in, display this div for mobile -->
            <div
                id="dropdown-content"
                v-if="loggedIn"
                class="js-nav-dropdown group-dropdown relative ml-[45vw] md:ml-[66vw] lg:ml-0"
            >
                <button
                    @click="handleMenuClose(!hide_menu)"
                    class="nav-btn-outline border-green-50 hover:bg-green-800 focus:bg-green-800 group ml-2 flex h-10 w-10 items-center justify-center rounded-full border-2 bg-green-base transition-colors hover:border-transparent focus:border-green-200 show"
                    id="profileDropdown"
                    aria-expanded="true"
                    data-bs-toggle="dropdown"
                    aria-label="profile"
                    data-v-step="0"
                >
                    <i
                        class="fa-solid fa-user fill-white group-hover:text-green-200 group-focus:fill-green-200"
                    ></i>
                </button>
                <div
                    v-bind:class="{ show: !hide_menu }"
                    class="fixed md:absolute profileMenuCustom mr-10 mt-5 dropdown-menu group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 z-10 hidden min-w-[14rem] whitespace-nowrap rounded-lg bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full px-2 py-2 shadow-lg !lg:py-8 lg:px-2 lg:shadow-lg"
                    aria-labelledby="profileDropdown"
                >
                    <span
                        class="font-display text-green-700 my-4 flex border-b w-full pb-5 border-green-50 select-none items-center justify-center text-center px-5 leading-none"
                    >
                        <p>{{ user.name }}</p>
                    </span>
                    <span
                        v-if="my_institutions.length"
                        class="font-display text-green-700 my-4 flex w-full pb-1 select-none items-center justify-center text-center px-5 leading-none"
                    >
                        <p>{{ $t("manageLbl") }}</p>
                    </span>
                    <div class="w-full scrollInstitutions">
                        <a
                            href="javascript:void(0)"
                            v-for="item in my_institutions"
                            :key="item.id"
                            @click="visitPage(item)"
                            class="hover:text-accent focus:text-accent hover:bg-green-50 flex items-center space-x-2 rounded-lg px-6 py-3 transition-colors"
                        >
                            <img :src="item.logo" class="h-5 w-5 mr-2" />
                            <span
                                class="font-display text-black/80 text-sm leading-1"
                            >
                                {{ item.name }}</span
                            >
                        </a>
                    </div>
                    <router-link
                        to="/institution/create"
                        class="hover:text-accent focus:text-accent hover:bg-green-50 flex items-center space-x-2 rounded-lg px-6 py-3 transition-colors"
                    >
                        <i class="fa-solid fa-plus mr-2 text-green-base"></i>
                        <span
                            class="font-display text-black/80 text-sm leading-1"
                        >
                            {{ $t("layout.content.addInstitutionLbl") }}</span
                        >
                    </router-link>
                    <hr class="my-3 text-green-50 border-green-50" />
                    <router-link
                        to="/profile"
                        class="hover:text-accent focus:text-green-base hover:bg-green-50 flex items-center space-x-2 rounded-lg px-6 py-3 transition-colors"
                    >
                        <i class="fa-solid fa-user mr-2 text-green-base"></i>
                        <span class="font-display text-black/80 text-sm">
                            {{ $t("layout.content.myProfileLbl") }}</span
                        >
                    </router-link>
                    <a
                        href="javascript:void(0)"
                        @click="logout"
                        class="hover:text-accent focus:text-accent hover:bg-green-50 flex items-center space-x-2 rounded-lg px-6 py-3 transition-colors"
                    >
                        <i
                            class="fa-solid fa-right-from-bracket mr-2 text-green-base"
                        ></i>
                        <span class="font-display text-black/80 text-sm">
                            {{ $t("layout.content.signOutLbl") }}</span
                        >
                    </a>
                </div>
            </div>
            <!-- When a user logs in, display this div for mobile end -->
            <!-- Mobile Menu Actions -->
            <div class="ml-auto flex lg:hidden">
                <!-- Mobile Menu Toggle -->
                <button
                    @click="$store.commit('setMobileMenu', true)"
                    class="group ml-6 flex h-10 w-10 items-center justify-center bg-none transition-colors focus:border-transparent"
                >
                    <!-- White Hamburger Menu-->
                    <svg
                        class="hamburger-light"
                        width="35"
                        height="16"
                        viewBox="0 0 35 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            x1="2"
                            y1="2"
                            x2="33"
                            y2="2"
                            stroke="white"
                            stroke-width="4"
                            stroke-linecap="round"
                        />
                        <line
                            x1="13"
                            y1="14"
                            x2="33"
                            y2="14"
                            stroke="white"
                            stroke-width="4"
                            stroke-linecap="round"
                        />
                    </svg>
                    <!-- Green Hamburger Menu-->
                    <svg
                        class="hamburger-dark hidden"
                        width="35"
                        height="16"
                        viewBox="0 0 35 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line
                            x1="2"
                            y1="2"
                            x2="33"
                            y2="2"
                            stroke="#009788"
                            stroke-width="4"
                            stroke-linecap="round"
                        />
                        <line
                            x1="13"
                            y1="14"
                            x2="33"
                            y2="14"
                            stroke="#009788"
                            stroke-width="4"
                            stroke-linecap="round"
                        />
                    </svg>
                </button>
            </div>
        </div>

        <v-tour name="myTour" :steps="steps" :options="myTourOptions">
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step
                        v-if="tour.steps[tour.currentStep]"
                        :key="tour.currentStep"
                        :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep"
                        :next-step="tour.nextStep"
                        :stop="tour.stop"
                        :skip="tour.skip"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                        :highlight="tour.highlight"
                    >
                        <template v-if="tour.currentStep === 0">
                            <div slot="actions" class="flex justify-between">
                                <button
                                    @click="closeTour(tour.stop)"
                                    class="border bg-white rounded-md px-3"
                                    style="color: #50596c"
                                >
                                    Okay
                                </button>
                                <button
                                    @click="tour.skip"
                                    class="border rounded-md px-3"
                                >
                                    Not now
                                </button>
                            </div>
                        </template>
                    </v-step>
                </transition>
            </template>
        </v-tour>
    </header>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            search: "",
            email: "",
            hide_banner: 0,
            clicks: 0,
            language: "",
            myTourOptions: {
                useKeyboardNavigation: false,
                startTimeout: 1000,
                labels: {
                    buttonSkip: "Not now",
                    buttonStop: "Add Institution",
                },
            },
            steps: [
                {
                    target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
                    content: `Select the profile icon, then select <strong>Add your institution</strong> to create and manage an institution`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            mobileMenu: "mobileMenu",
            user: "profile/user",
            loggedIn: "profile/loggedIn",
            hide_menu: "hide_menu",
            isOpen: "isOpen",
            onLine: "onLine",
            my_institutions: "institutions/my_institutions",
        }),
    },
    watch: {
        isOpen(value) {
            if (value) {
                document.body.style.setProperty("overflow", "hidden");
            } else {
                document.body.style.removeProperty("overflow");
            }
        },
        language(value) {
            localStorage.setItem("language", value);
            window.location.reload();
        },
        $route() {
            if (this.loggedIn && this.user.signup_reason === "Add college/institution" && this.$route.name === "view-programmes") {
                if (this.user.tour_complete !== 1) {
                    this.$tours["myTour"].start();
                }
            }
        },
    },
    methods: {
        ...mapActions({
            getProfile: "profile/getProfile",
            setReminder: "profile/setReminder",
            getMyInstitutions: "institutions/getMyInstitutions",
            logoutUser: "profile/logout",
            tourComplete: 'profile/tourComplete'
        }),
        logout() {
            this.logoutUser({}).then(() => {
                this.$store.commit("setHideMenu", !this.hide_menu);
                localStorage.removeItem("4p@token");
                window.location.href = "/";
            });
        },
        async handleMenuClose(status) {
            await this.$store.commit("setHideMenu", status);
        },
        visitProfile() {
            this.$store.commit("setHideMenu", !this.hide_menu);
            this.$router.push("/profile");
        },
        remindMeLater() {
            this.setReminder({
                email: this.email,
            });
        },
        dismiss() {
            this.hide_banner = 1;
            localStorage.setItem("hide_banner", JSON.stringify(1));
        },
        toggleMenu() {
            this.$store.commit("setHideMenu", !this.hide_menu);
        },
        visitPage(institution) {
            window.location.href = "/institutions/" + institution.public_url;
        },
        drawer() {
            this.$store.commit("setIsOpen", !this.isOpen);
        },
        closeTour(fn) {
            fn();
            this.tourComplete({tour_complete: true}).then(()=>{
                this.getProfile({})
            })
            setTimeout(() => {
                var openMenu = document.getElementById("profileDropdown");
                openMenu.click();
            }, 1000);
        },
        openHowItWorksModal() {
            document.getElementById('howItWorksModal').showModal()
        },
    },
    async mounted() {
        let status = JSON.parse(localStorage.getItem("hide_banner"));
        this.hide_banner = status ? status : 0;
        if (this.loggedIn) {
            await this.getProfile({});
            await this.getMyInstitutions({
                admin_id: this.user.id,
            });
        }
        document.addEventListener("click", (e) => {
            const dropdown = document.getElementById("dropdown-content");
            if (dropdown) {
                if (dropdown.contains(e.target)) return; // if the clicked element is inside the dropdown or it's the dropdown itself, do nothing
                if (!this.hide_menu) {
                    this.$store.commit("setHideMenu", true);
                }
            }
        });
    },
};
</script>

<style>
.scrollInstitutions {
    overflow-y: scroll;
    max-height: 132px;
}

@media (min-width: 1025px) {
    .howItWorksVerticalLine::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: grey;
    right: -14px;
    }
}

@media (min-width: 801px) {
    .profileMenuCustom{
        margin-right: 0 !important;
    }
}
</style>

import api from "../api";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        delivery_modes: []
    },
    getters: {
        delivery_modes: state => state.delivery_modes
    },
    mutations: {
        setDeliveryModes(state, data) {
            state.delivery_modes = data;
        }
    },
    actions: {
        async getDeliveryModes({commit}, filters) {
            try {
                const {data} = await api.getDeliveryModes(filters);
                commit('setDeliveryModes', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }
}

import api from "../api";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        programme_level_bases: []
    },
    getters: {
        programme_level_bases: state => state.programme_level_bases
    },
    mutations: {
        setProgrammeLevelBases(state, data) {
            state.programme_level_bases = data;
        }
    },
    actions: {
        async getProgrammeLevelBases({commit}, filters) {
            try {
                const {data} = await api.getProgrammeLevelBases(filters);
                commit('setProgrammeLevelBases', data);
            } catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }
}

export default [
    {
        path: '/sign-in',
        name: 'sign-in',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/login'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/forgot-password'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/reset',
        name: 'reset',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/reset'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },

    {
        path: '/sign-up',
        name: 'sign-up',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/register'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/verify-email-notification',
        name: 'verify-email-notification',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/verify-email-notification'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/verify-email',
        name: 'verify-email',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/verify-email.vue'),
        },
        meta: {
            guest: true,
            redirect_if_authenticated: true
        },
    },
    {
        path: '/profile',
        name: 'profile',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/profile'),
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/setup-profile',
        name: 'setup-profile',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/setup-profile'),
        },
        meta: {
            auth: true,
            dont_show_quick_links: true
        },
    },
    {
        path: '/not-found',
        name: 'not-found',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/not-found'),
        },
        meta: {
            guest: true,
        },
    },
    {
        path: '/auth/google/callback',
        name: 'google-callback',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/auth/google-callback'),
        },
        meta: {
            guest: true,
        },
    },

]

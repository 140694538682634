export default [
    {
        path: '/home',
        name: 'home',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/dashboard/home'),
        },
        meta: {
            guest: true,
            description: 'Fourth Profile connects students and working professionals to colleges and universities globally. It also helps insitutions to market their university courses and programs',

        },
    },
    {
        path: '/about',
        name: 'about',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/dashboard/about'),
        },
        meta: {
            guest: true,
            description: 'Whether you are looking for your dream institution and course, or you are an admission officer or student recruiter, and you want to promote your institution courses, Fourth Profile is here to help',
        },
    },
    {
        path: '/',
        name: 'home',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/dashboard/home'),
        },
        meta: {
            guest: true,
            description: 'Fourth Profile connects students and working professionals to colleges and universities globally. It also helps insitutions to market their university courses and programs',
        },
    }
]

<template>
    <div class="flex flex-col min-h-screen">
        <app-header></app-header>
        <main class="flex flex-1 flex-col">
            <router-view />
        </main>
        <app-footer></app-footer>
    </div>
</template>
<script>
import AppHeader from "./views/layout/content";
import MobileMenu from "./assets/js/modules/MobileMenu";
import CopyToClipboard from "./assets/js/modules/CopyToClipboard";
import Header from "./assets/js/modules/Header";
import Sliders from "./assets/js/modules/Sliders";
import Tooltips from "./assets/js/modules/Tooltips";
import AppFooter from "./views/layout/footer";
import { mapActions, mapGetters } from "vuex";

export default {
    metaInfo: {
        title: "Find Courses and Opportunities in Tech",
        titleTemplate: "%s | Fourth Profile",
        meta: [
            {
                vmid: "description",
                name: "description",
                content:
                    "Fourth Profile connects students and working professionals to courses and and opportunities in tech globally. Get connected to colleges that offer tech courses, their course intakes schedules and financial aid available to you. Also, if you’re an admission officer or a student recruiter, and you want to promote your tech courses, Fourth Profile is here to help.",
            },
            {
                vmid: "keywords",
                name: "keywords",
                content:
                    "Tech courses, scholarships for tech courses, Grants for tech courses, student loans for tech courses, How to Transition into Tech in under a year",
            },
            {
                property: "og:image",
                content: "https://fourthprofile.com/assets/img/4p_logo.png",
            },
            {
                property: "og:image:height",
                content: 200,
            },
            {
                property: "og:image:width",
                content: 200,
            },
        ],
    },
    components: {
        AppHeader,
        AppFooter,
    },
    computed: {
        ...mapGetters({
            onLine: "onLine",
        }),
    },
    methods: {
        ...mapActions({
            setOnlineStatus: "setOnlineStatus",
        }),
        updateOnlineStatus(e) {
            const { type } = e;
            this.$store.commit("setOnlineStatus", type === "online");
        },
    },
    mounted() {
        new MobileMenu();
        new CopyToClipboard();
        new Header();
        new Sliders();
        new Tooltips();
        new CopyToClipboard();
        window.addEventListener("online", this.updateOnlineStatus);
        window.addEventListener("offline", this.updateOnlineStatus);
        let session_id = Math.random().toString(36).slice(0, 30);
        localStorage.setItem("session_id", session_id);
        let pusher = new window.Pusher("474ae8e0a83effd5427d", {
            cluster: "ap2",
        });
        let channel = pusher.subscribe("google");
        channel.bind("sign-in" + session_id, function (data) {
            if (data.status === 200) {
                localStorage.setItem("4p@token", data.token);
                setTimeout(() => {
                    this.$toast.open({
                        message: data.message,
                        type: "success",
                        duration: 10000,
                    });
                }, 2000);
                let redirect_url = localStorage.getItem("redirect_url");
                window.location.href = !data.user.name
                    ? "/setup-profile"
                    : redirect_url
                    ? redirect_url
                    : "/courses";
                localStorage.removeItem("redirect_url");
            } else {
                setTimeout(() => {
                    this.$toast.open({
                        message: data.message,
                        type: "error",
                        duration: 10000,
                    });
                }, 2000);
                window.location.href = "/sign-up";
            }
        });
    },
};
</script>

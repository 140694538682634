class Header {
  constructor() {
    this.header = document.querySelector(".js-page-header")
    this.whitelogo = document.querySelector("#main-logo")
    this.darklogo = document.querySelector("#logo-dark")

    this.lighthamburger = document.querySelector(".hamburger-light")
    this.darkhamburger = document.querySelector(".hamburger-dark")

    this.navtext = document.querySelectorAll(".nav-text")
    this.outlinedbtn = document.querySelector(".nav-btn-outline")

    if (this.header) {
      this.initStickyNavbar()
      this.events()
    }
  }

  initStickyNavbar() {
    if (window.scrollY > 0) {
      this.header.classList.add("js-page-header--is-sticky")
      this.header.classList.remove("js-page-header--top")
      // Toggle the logo
      this.whitelogo.classList.add("hidden")
      this.darklogo.classList.remove("hidden")

      // Toggle the text colors
      this.navtext.forEach(link =>{
        link.classList.add("nav-text--scrolled")
      })
       
      // Outline button
      this.outlinedbtn.classList.add("nav-btn-outline--scrolled")


      // Toggle the mobile hamburger menu color
      this.lighthamburger.classList.add("hidden")
      this.darkhamburger.classList.remove("hidden")

    } else {
      this.header.classList.remove("js-page-header--is-sticky")
      this.header.classList.add("js-page-header--top")

      // Toggle the logo
      this.whitelogo.classList.remove("hidden")
      this.darklogo.classList.add("hidden")

      // Toggle the text colors 
      this.navtext.forEach(link =>{
        link.classList.remove("nav-text--scrolled")
      })

      // Outline button
      this.outlinedbtn.classList.remove("nav-btn-outline--scrolled")

      // Toggle the mobile hamburger menu color
      this.lighthamburger.classList.remove("hidden")
      this.darkhamburger.classList.add("hidden")
    }
  }

  events() {
    window.addEventListener("scroll", e => this.initStickyNavbar(e))
  }
}

export default Header

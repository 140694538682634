import api from "../api";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        analytics: {
            page_users: {data: [],per_page: 3,total: 0},
            programme_users: {data: [],per_page: 3,total: 0}
        }
    },
    getters: {
        analytics: state => state.analytics
    },
    mutations: {
        setAnalytics(state,data){
            state.analytics = data;
        }
    },
    actions: {
        async recordView(context, payload) {
            return await api.recordView(payload);
        },
        async getAnalytics({commit},payload){
            try {
                const response = await api.getAnalytics(payload);
                commit('setAnalytics',response)
            }catch (e) {
                Vue.$toast.open({
                    message: e.response.data.message,
                    type: 'error',
                    duration: 10000
                });
            }
        }
    }
}

import profile from "./profile.api";
import notifications from "./notifications.api"
import institutions from "./institutions.api";
import faculties from "./faculties.api";
import delivery_modes from "./delivery_modes.api";
import delivery_timings from "./delivery_timings.api";
import disciplines from "./disciplines.api";
import instruction_languages from "./instruction_languages.api";
import programme_level_bases from "./programme_level_bases.api";
import programme_levels from "./programme_levels.api";
import analytics from "./analytics.api";
export default {
    ...profile,
    ...notifications,
    ...institutions,
    ...faculties,
    ...delivery_modes,
    ...delivery_timings,
    ...disciplines,
    ...instruction_languages,
    ...programme_level_bases,
    ...programme_levels,
    ...analytics
}

export default [
    {
        path: '/faculties/create/:public_url/:id?',
        name: 'faculties-create',
        components: {
            default: () => import(/* webpackChunkName: "auth" */ '../views/faculties/create-faculty'),
        },
        meta: {
            auth: true,
            admin: true
        },
    }
]
